#audio-record-container {
    height: 100%;
    width: 450px;
    position: absolute;
    padding: 10px;
    left: calc(50% - 225px);
    text-align: center
}

#audio-record-container .audio-end-message {
    text-align: center;
    vertical-align: middle;
    border-radius: 5px;
    position: absolute;
    color: #222222;
    top: calc(50% + 0px);
    left: 0;
    height: 170px;
}
#audio-record-container .audio-end-message canvas {
    max-width: 100%;
}

#audio-record-container .audio-sent-message {
    text-align: center;
    vertical-align: middle;
    border-radius: 5px;
    position: absolute;
    top: calc(50% - 0px);
    left: 0;
}

#audio-record-container .buttonCall {
    position: relative;
    background: rgba(33,37,41,0.75);
    border: 0px solid black;
    top: calc(50% - 180px);
    padding: 15px
}
#audio-record-container .buttonCall svg{
    color: #ffffff
}
