@media all and (min-width: 480px) {
    input[type=number]::-webkit-inner-spin-button {
        opacity: 1
    }

    .loading-type table, th, td {
        border: 1px solid #adb5bd;
        text-align: center;
    }

    .loading-type td {
        font-size: 13px;
        height: 38px;
    }

    .input-loading-type {
        width: 200px;
        font-size: 13px;
        height: 28px;
    }

    .col4 {
        width: 125px;
        text-align: center;
    }

    .col4-top {
        padding-top: 20px;
        vertical-align: top;
    }

    .box-routes-chk {
        display: block;
        /* border: 1px solid black; */
        padding: 10px;
        width: 100%;
        background-color: #ffffff;
    }

    .box-routes-list {
        display: block;
        position: absolute;
        width: 315px;
        left: 330px;
        margin-top: -48px;
        border: 1px solid black;
        background-color: #ffffff;
    }
    
    .loading-type li {list-style-type: none; height: 28px; vertical-align: middle;}
    .loading-type input[type=checkbox] {width: 16px; }
    
}