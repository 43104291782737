.arrow {
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    height: 10px;
    position: absolute;
    margin: 0 5px;
    top: calc(50% - 6px);
}

.h-arrow {
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    height: 10px;
    position: absolute;
    left: calc(50% - 6px);
}

.arrow-container {
    height: 100px;
    width: 22px;
    margin-right: 2px;
    position: absolute;
    top: calc(50% - 50px);
    background: #03a9f4;
    cursor: pointer;
}

.h-arrow-container {
    width: 100px;
    height: 22px;
    position: absolute;
    left: calc(50% - 50px);
    background: #03a9f4;
    cursor: pointer;
}

.left-container {
    right: -23px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 1;
}

.right-container {
    left: -22px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    z-index: 1;
}

.up-container {
    bottom: -22px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    z-index: 1;
}

.down-container {
    top: -22px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    z-index: 1;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    top: 8px;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    top: 4px;
}

.arrow.left {
    margin-left: 8px;
}
.full-box-icon{
    position: absolute;
    z-index: 111;
    border: 1px solid #ffffff;
    color: #fff;
    width: 30px;
    height: 30px;
    text-align:center;
    line-height: 30px;
    cursor: pointer
}
.full-box-icon svg {
    transform: rotate(0.13turn)
}

.full-box-icon-left{
    right: 8px;
    top: 8px;
}

.full-box-icon-right {
    left: 8px;
    top: 8px;
}

.VCollapse-resize-left {
    width: 10px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: e-resize;
}

.VCollapse-resize-right {
    width: 10px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    cursor: e-resize;
}

.VCollapse-resize-top {
    width: 100vw;
    height: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: n-resize;
}

.VCollapse-resize-bottom {
    width: 100vw;
    height: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    cursor: n-resize;
}