@media all and (min-width: 480px) {
    .detail-setting input[type=number]::-webkit-inner-spin-button {
        opacity: 1
    }

    .detail-setting table{
        border: 1px solid black;
        padding-top: 15px;
    }

    .detail-setting th, td {
        border: 0px solid black;
        text-align: start;
    }

    .detail-setting td {
        font-size: 13px;
    }
    
    .tr-margin td{
        height: 10px;
    }

    .col1 {
        padding-left: 20px;
        width: 160px;
    }

    .col2 {
        width: 170px;
    }

    .col3 {
        width: 65px;
    }
    .detail-input {
        width: 150px;
        font-size: 13px;
        height: 28px;
    }

    .detail-input1 {
        width: 60px;
        font-size: 13px;
        height: 28px;
    }
    

    .div-header {
        display: block;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .temporary_disabled {
        display: none;
    }
}

.w-90 {
    width: 90%;
}

.h-28 {
    height: 28px;
}

.w-50 {
    width: 50%;
}