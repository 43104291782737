.route-list-item {
    cursor: default;
}

.route-list td, .route-list th {
    vertical-align: middle;
    text-align: center;
}

#dropdown-route-s-option {
    position: absolute;
    right: -5px;
    top: -3px;
    color: #212529;
}

#dropdown-route-s-option::after {
    display: none;
}