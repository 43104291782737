.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.form-inline label {
    margin-right: 10px;
}

.form-inline select, .form-inline input {
    vertical-align: middle;
}
