@media all and (min-width: 480px) {
    input[type=number]::-webkit-inner-spin-button {
        opacity: 1
    }

    .prox-alarm table, th, td {
        border: 0px solid black;
    }

    .prox-alarm td {
        text-align: left;
        font-size: 13px;
        height: 38px;
    }

    .dropdown-w-100 {
        width: 600px;
    }

    .input-fixedszie {
        font-size: 13px;
        height: 28px;
    }

    .header-button {
        width: 125px;
    }
}