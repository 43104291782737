

@media all and (min-width: 480px) {
    .voice-register table, th, td {
        border: 1px solid black;
        vertical-align: center;
        text-align: center;
    }

    .voice-register th {
        border: 1px solid black;
        width: 10%;
        text-align: center;
    }

    .voice-register td {
        padding-left: 5px;
        padding-right: 5px;
        border: 1px solid black;
        text-align: center;
    }
    
    .voice-register .th-small {
        width: 10%;
    }

    .voice-register .th-large {
        width: 20%;
    } 
    
    .sort-button {
        position: inherit;
        margin-left: 20px;
        width: 45px;
        height: 30px;
    }
}