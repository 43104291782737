.coordinate-inputs {
    /* position: absolute;
    top: 30px;
    right: 30px; */
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    /* z-index: 1000; */
    background-color: #ffffffc7;
    padding: 3px 5px;
}
.coordinate-inputs input {
    padding: 2px 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 90px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .1);
}
.coordinate-inputs button {
    padding: 8px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color .3s;
}
.coordinate-inputs button {
    padding: 8px;
    background-color: #03a9f4;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color .3s;
}