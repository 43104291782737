.buttonGroup {
    position: absolute;
    top: 0px;
    right: 10px;
}

.w-75 {
    width: 75% !important;
}

.w-20 {
    width: 20% !important;
}

.trajectory-container {
    background: white;
    position: relative;
    padding: 10px;
}

.trajectory-container .form-container {
    margin-top: 35px;
}

.trajectory-container .form-container .form-row.row{
    padding-top: 5px;
}