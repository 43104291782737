.message-container {
    background: white;
    position: relative;
    padding: 10px;
}

.t-10 {
    top: 10px !important;
}

.message-container .container-up {
    padding-left: 40px;
    margin-top: -26px;
}

.w-90 {
    width: 90% !important;
}