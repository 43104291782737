.modal-full {
    width: 90%;
    max-width: none !important;
}

/* ======================================================= * * Template Style * * ======================================================= */
body {
    font-family: Meiryo, sans-serif;
    background: #e5f0f7;
    color: #1f1f1f;
    font-size: 14px;
    position: relative;
    overflow:hidden
}

a {
    -moz-transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -moz-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    color: #03a9f4;
    text-decoration: none;
    position: relative;
    font-size: 14px;
}

a:hover,
a:active,
a:focus {
    color: #000;
    outline: none !important;
    text-decoration: none;
}

input.form-control:hover,
select.form-control:hover,
input.form-control:active,
select.form-control:active,
input.form-control:focus,
select.form-control:focus {
    color: #000;
    outline: none !important;
    text-decoration: none;
    box-shadow: none;
}
.btn:focus{
    box-shadow:none
}
.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
}
td,th {
    border-color: #adb5bd!important
}
.btn-secondary {
    background: #bdbdbd;
    border-color: #bdbdbd;
}

.btn-primary {
    background: #03a9f4;
    border-color: #03a9f4;
}

#root{
    width: 100%;
    overflow:hidden
}
/* ======================================================= Header * ======================================================= */
#page-header {
    z-index: 999;
    position: relative;
    width: 100%;
}

#page-header nav {
    -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    background-color: #fafafa;
    padding: 6px 7px;
    position: relative;
    z-index: 999;
    white-space: nowrap;
    height: 50px;
}

#page-header nav .navbar-nav {
    border-left: 1px solid #ccc;
    padding-left: 10px;
}

#page-header nav .nav-link {
    color: #1f1f1f;
    padding: 4px 10px;
}

#page-header nav .nav-link:hover,
#page-header nav .nav-link:active,
#page-header nav .nav-link:focus {
    color: #03a9f4;
}
/* ======================================================= Aside * ======================================================= */
aside,
.aside-left-modal {
    background: #fff;
    overflow-x: hidden;
    overflow-y: auto;
}

aside .card,
.aside-left-modal .card {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #dedede;
}

aside .card .card-header,
.aside-left-modal .card .card-header {
    border: none;
    padding: 0;
    position: relative;
    /* Horizontal line */
    /* Vertical line */
}

aside .card .card-header .collapsible-link,
.aside-left-modal .card .card-header .collapsible-link {
    padding: 0.7rem 1rem 0.7rem 2.7rem;
    text-align: left;
    color: #333;
}

aside .card .card-header .collapsible-link::before,
.aside-left-modal .card .card-header .collapsible-link::before {
    content: "";
    width: 14px;
    height: 2px;
    background: #333;
    position: absolute;
    top: calc(50% - 1px);
    left: 1rem;
    display: block;
    transition: all 0.3s;
}

aside .card .card-header .collapsible-link::after,
.aside-left-modal .card .card-header .collapsible-link::after {
    content: "";
    width: 2px;
    height: 14px;
    background: #333;
    position: absolute;
    top: calc(50% - 7px);
    left: calc(1rem + 6px);
    display: block;
    transition: all 0.3s;
}

aside .card .card-header .collapsible-link[aria-expanded="true"]::after,
.aside-left-modal
.card
.card-header
.collapsible-link[aria-expanded="true"]::after {
    transform: rotate(90deg) translateX(-1px);
}

aside .card .card-header .collapsible-link[aria-expanded="true"]::before,
.aside-left-modal
.card
.card-header
.collapsible-link[aria-expanded="true"]::before {
    transform: rotate(180deg);
}

aside .card .card-header .controls-more,
.aside-left-modal .card .card-header .controls-more {
font-size: 16px;
padding: 1px 5px;
cursor: pointer;
position: absolute;
right: 15px;
bottom: 0;
top: 0;
margin: auto;
height: 27px;
color: #444;
}

aside .card .card-body,
.aside-left-modal .card .card-body {
    padding: 5px;
}

aside .area-title,
.aside-left-modal .area-title {
    display: block;
    padding: 0.7rem 1rem;
    text-align: left;
    font-size: 16px;
    position: relative;
    background: #03a9f4;
    color: #fff;
}

aside .area-title .svg-inline--fa,
.aside-left-modal .area-title .svg-inline--fa {
    font-size: 18px;
    margin-right: 5px;
}

aside .area-title .close,
.aside-left-modal .area-title .close {
    position: absolute;
    right: 9px;
    width: 20px;
    height: 20px;
    top: 10px;
    cursor: pointer;
}

aside .table,
.aside-left-modal .table {
    margin-bottom: 0;
}

#main-container {
    width: calc(100vw - 1200px);
}

.modal .modal-body {
    background: #fefefe;
}

.modal .modal-footer .btn {
    min-width: 120px;
}

.modal .modal-footer > * {
    margin: 0 0.5rem;
}

.result-table {
    border: 1px solid #000;
    min-height: 200px;
}

.table {
    background: #fff;
}

.table td,
.table th {
    padding: 0.25rem;
}

.table thead th {
    background: #fafafa;
    border-width: 1px;
}

.route-management {
    position: absolute;
    bottom: 30px;
    left: calc(50% - 135px);
}

.area-management {
    position: absolute;
    bottom: 30px;
    left: calc(50% - 135px);
}

.image-attach-mode {
   
    /* background: rgba(0, 0, 0, 0.3);; */
    height: 100%;
}

.image-attach-mode .button-group-image-attach {
    position: absolute;
    bottom: 0px;
    left: calc(50% - 15px);
    width: 300px;
}

.audio-record {
    background: rgba(225, 225, 225, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0px;
    left: 0px;
    align-items: center;
    vertical-align: middle;
}

.emergency-message {
    border: 1px solid black;
    border-radius: 3px;
    font-size: 19px;
    position: absolute;
    padding: 15px 15px;
    top: 50px;
    left: 30px;
    min-width: 400px;
    width: 450px;
    height: 160px;
    background-color: white;
    text-align: center;
}

.modal-dialog.modal-lg.modal-dialog-centered {
    margin: 0 auto !important;
}

.relative {
    position: relative;
}

.search-place-container {
    position: absolute;
    top: 10px;
    left: 25px;
    width: 350px;
}

.pac-logo:after {
    display: none;
}
.dropdown-menu{
    border-radius: 0
}
.buttonEdit{
    float: right
}
.clearfix::after {
    content: "";
    clear: both;
    display: table;
}
.info-app{
    text-align:center;
    font-size: 1.3rem
}
.react-datepicker-popper{
    z-index:11!important;
}
.input-date{
    max-width: 170px;
    margin-right: 2px
}
.input-time{
    max-width: 130px;
    margin-left: 2px
}
select.form-control-sm{
    max-width: 643px
}

.loadingCssContainer {
    position: absolute;
    z-index: 10000;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #777;
    opacity: 0.7;
}


.mapContextMenu {
    display: none;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(255, 255, 255);
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px;
    cursor: pointer;
    font-size: 1rem;
    text-align: center;
    position: absolute;
    z-index: 1;
    color: #0d1f49;
    width: auto;
    margin: 1px; /*Please note that this margin is necessary otherwise browser will open its own context menu*/
}

.mapContextMenu.left-context:after {
    content: "";
    position: absolute;
    bottom: -32px;
    left: -2px;
    width: 0;
    border-top: 32px solid white;
    border-right: 20px solid transparent;
}

.mapContextMenu.right-context:after {
    content: "";
    position: absolute;
    bottom: -32px;
    right: -2px;
    width: 0;
    border-top: 32px solid white;
    border-left: 20px solid transparent;
}

.mapContextMenu > div{
    width: auto;
    padding: 5px 30px;
    cursor: pointer;
}

[class$='-table-resize'] {
    width: 6px;
    position: absolute;
    right: -3px;
    top: 0;
    cursor: e-resize;
    z-index: 1;
}
.tab-content > #navRightTab-tabpane-route.tab-pane:not([class*='active']) {
    display: block;
    position: absolute;
    width: 0;
}
@media (max-width: 1024px) {
   .modal-backdrop{
    z-index: 999999;
    }
    #navRight .area-list button{
        position:relative;
        z-index:99999
    }
    .modal{
        z-index:99999999
    } 
}

.noMap {
    width: 100vw;
    height: 100vh;
    text-align: center;
    position: relative;
}

.noMap>span {
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.Toastify__toast-body {
    white-space: pre-line;
}