.independent-operation-container {
    position: absolute;
    bottom: 75px;
    left: 20px;
    width: 240px;
    border-radius: 4px;
    border: 1px solid;
}

.independent-operation-container .title, .placement-limit-container .title {
    background-color: #00b0f0;
}

.placement-limit-container {
    position: absolute;
    bottom: 18px;
    left: 20px;
    width: 240px;
    border-radius: 4px;
    border: 1px solid;
}
