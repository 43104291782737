.aside-left-modal {
    border-right: 1px solid #cccccc;
}

.aside-left-modal .card .card-body {
    height: calc(50vh - 100px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.aside-left-modal .card .card-body .table-hover tbody tr:hover {
    background: dodgerblue;
    color: #ffffff;
}

.inline {
    display: inline;
}

.mar-5 {
    margin: 5px;
}

.focus {
    border: 2px solid blue;
}

.w-25 {
    width: 25% !important;
}

.btn-135 {
    width: 135px !important;
}

.routeEditContainer {
    background: white;
    max-height: calc(100vh - 34px);
    overflow-y: auto;
    overflow-x: hidden;
}

.select-w-50 select {
    width: 50% !important;
}