#bottomTab.show {
    width: 100%;
    transition: height 0.5s ease;
    border-top: 1px solid #03a9f4
}

#bottomTab.hide {
    width: 0;
    height: 0;
    transition: height 0.5s ease;
    overflow: hidden;
}

#bottomTab .nav-item.nav-link {
    cursor: pointer;
    color: white;
    background: #03a9f4;
    border-right: 1px solid;
    border-bottom: 1px solid;
}

#bottomTab .nav-item.nav-link.active {
    cursor: default;
    border-bottom: 2px solid #03a9f4;
}

#bottomTab {
    background: #ffffff;
    padding-top: 5px
}

#bottomTab .nav-tabs {
    border-bottom: 2px solid #03a9f4;
}

#bottomTab .nav-tabs .nav-item {
    margin-bottom: 1px;
}

#bottomTab .nav-tabs .nav-item.active {
    margin-bottom: -1px;
}