
@media all and (min-width: 480px) {
    .box-title{
        border-bottom: 1px solid #cccccc
    }
    .page-header {
        font-size: 15px;
        font-weight: bold;
    }
    
    .box {
        padding: 0px 15px;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    
    table, th, td {
        border: 1px solid black;
        vertical-align: center;
    }

    th {
        text-align: center;
        font-size: 15px;
        height: 40px;
    }

    td {
        text-align: center;
        font-size: 13px;
        height: 40px;
    }

    .td-left {
        text-align: left;
    }

    .th-header {
        width: 100%;
        background-color: rgba(0, 123, 255, 0.25);
        border: 1px solid #adb5bd;
        font-size: 15px;
        margin-top: 15px;
        height: 40px;
    }



    .bg-blue {
        background-color: rgba(3, 169, 244, 0.5);
    }

    .align-right {
        position: absolute;
        right: 15px;
        display: block, 
    }

    .plus-button {
        right: 20px;
    }

    .button-img {
        right: 20px;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        
    }
    .button-img:hover {
        background-color: rgba(0, 0, 0, 0);
        border: none;
    }

    .button-img:focus {
        background-color: rgba(0, 0, 0, 0);
        border: none;
    }

    .button-img:focus-visible {
        background-color: rgba(0, 0, 0, 0);
        border: none;
    }

    .page-item button {
        background-color: rgba(0, 0, 0, 0);
        border: none;
        margin: 5px;
        font-size: 16px;
        font-weight: bold;
        color: blue;
    }

    .page-item button:hover {
        background-color: rgba(0, 0, 0, 0);
        border: none;
    }

    .page-item button:focus {
        background-color: rgba(0, 0, 0, 0);
        border: none;
    }

    .page-item button:focus-visible {
        background-color: rgba(0, 0, 0, 0);
        border: none;
    }

    .page-item button:active {
        background-color: rgba(0, 0, 0, 0);
        border: none;
        color: red;
    }

    .register {
        width: 100%;
    }

    .cancel-button {
        background-color: #00bfe0;
        border: none;
        color: white;
        text-align: center;
        text-decoration: none;
        font-size: 15px;
        padding: 2px 10px;
        border: 1px solid black;
    }

    .error > input.form-control {
        border: 1px solid red;
    }

    .errClass {
        color: red;
    }
}

table#auto-message td, table#auto-message td * {
    margin-top: 5px;
    vertical-align: top;
}