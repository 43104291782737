.area-list td, .area-list th {
    vertical-align: middle;
    text-align: center;
}

#dropdown-area-1-option.dropdown-toggle,
#dropdown-area-2-option.dropdown-toggle,
#dropdown-area-0-option.dropdown-toggle {
    position: absolute;
    right: -5px;
    top: -3px;
    color: #212529;
}

#dropdown-area-1-option.dropdown-toggle::after,
#dropdown-area-2-option.dropdown-toggle::after,
#dropdown-area-0-option.dropdown-toggle::after {
    display: none;
}

.w-100px {
    width: 100px;
}

.area-comment {
    color: red;
    font-weight: bold;
}

.area-comment span {
    display: block;
    line-height: 1.5;
    font-size: 14px;
}