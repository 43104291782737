.MultiCheckBox li {
    list-style-type: none;
}

ul.MultiCheckBox {
    height: 100px;
    overflow-y: auto;
    padding: 10px;
}

.MultiCheckBox {
    border: 1px solid #ced4da;
}

.MultiCheckBox-sub {
    top: -12px;
    position: relative;
}