.loadingCssContainer {
    position: absolute;
    z-index: 10000;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #777;
    opacity: 0.7;
}

.w-100 {
    width: 100%;
}

.tableUser {
    height: calc(100vh - 280px);
    overflow-y: auto;
}