
.w-25 {
    width: 25% !important;
}

.btn-135 {
    width: 135px;
}

.btn-120 {
    width: 120px;
}

.areaEditContainer {
    /*background: white;
    height: calc(100vh - 34px);
    overflow-y: auto;
    overflow-x: hidden;*/
    padding: 2rem 0
}

.col-0 {
    display: none;
}
.col-show {
    max-width: 460px
}
.areaEditContainer .col-form-label{
    text-align: right;
    padding-right: 1rem
}
.space-time {
    line-height: 30px;
    padding-left: 5px;
    padding-right: 5px;
}
