
@media all and (min-width: 480px) {
    .unit-count table, th, td {
        border: 1px solid black;
        vertical-align: center;
        text-align: center;
    }

    .unit-count th {
        border: 1px solid black;
        text-align: center;
    }

    .unit-count td {
        padding-left: 5px;
        padding-right: 5px;
        border: 1px solid black;
        text-align: center;
    }

    .th-s {
        width: 9%;
    }
    
    .th-l {
        width: 18%;
        
    }
    

    
}