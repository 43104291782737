@media all and (min-width: 480px) {
    .blue-button {
        background-color: #00bfe0;
        color: white;
        text-align: center;
        text-decoration: none;
        font-size: 15px;
        padding: 2px 10px;
        border: 1px solid black;
        border-radius: 5px;
        width: 150px;
    }

    .handle {
        width: 200px;
        height: 150px;
        line-height: 40px;
        cursor: move;
        text-align: center;
      }

    .handle:not(.react-draggable-dragging) {
        -webkit-transition: -webkit-transform 0.5s ease-out; /* Safari */
        transition: transform 0.5s ease-out;
      }
}