.audio-call-container {
    background: white;
    position: relative;
    padding: 10px;
}

.audio-call-container .form-container .form-row.row {
    padding-top: 5px;
    padding-bottom: 10px;
}

.audio-call-container td, .audio-call-container th {
    text-align: center;
}

.audio-call-container .buttonCall {
    color: white;
    background: #666666;
    top: 15px;
    right: 15px;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    position: absolute;
}

.audio-call-container .buttonCall svg {
    left: 16px;
    top: 12px;
    position: absolute;
}

.audio-call-container .audioUpload {
    color: white;
    top: 23px;
    right: 125px;
    height: 50px;
    width: 150px;
    position: absolute;
}

.audio-call-container .audioCall {
    color: white;
    background: #666666;
    top: 15px;
    right: 75px;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    position: absolute;
}

.audio-call-container .audioCall svg {
    left: 16px;
    top: 16px;
    position: absolute;
}
.audio-call-container .sound-wave-b {
    display: none
}