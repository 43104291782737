.table-container {
    overflow-y: auto;
    border: 1px solid #ccc; 
    margin-top: 20px;
}

.table-container table {
    width: 100%; 
    border-collapse: collapse; 
}

.table-container th, .table-container td {
    padding: 10px; 
    text-align: left; 
    border: 1px solid #ccc; 
}

.table-container th {
    background-color: #f2f2f2; 
    position: sticky; 
    top: 0;
    z-index: 1;
}


@media all and (min-width: 480px) {
    .email-manage table, th, td {
        border: 1px solid black;
        vertical-align: center;
        text-align: center;
    }

    .email-manage th {
        border: 1px solid black;
        height: 40px;
        width: 10%;
    }

    .email-manage tr th:first-child {
        width: 30%
    }

    .email-manage td {
        padding-left: 5px;
        padding-right: 5px;
        border: 1px solid black;
    }


    .btn-50 {
        width: 50px;
    }
}