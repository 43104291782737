#searchinput {
    width: 200px;
}

#searchclear {
    position: absolute;
    right: 10px;
    top: -5px;
    bottom: 0;
    height: 14px;
    margin: auto;
    font-size: 14px;
    cursor: pointer;
    color: #ccc;
}

#infowindow-content .title {
    font-weight: bold;
    top: 2px;
    left: 5px;
    position: relative;
    padding-right: 5px;
}

#infowindow-content {
    padding-right: 5px;
    padding-bottom: 3px;    
}

#infowindow-content #place-address {
    position: relative;
    top: 3px;
}

.w-30px {
    width: 30px;
}

.no-border-right {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.no-border-left {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}