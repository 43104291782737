#navRight .nav-tabs {
    background: #03a9f4;
    border-bottom: none;
}

#navRight .nav-item.nav-link {
    width: calc(50% - 22px);
    cursor: pointer;
    color: white;
    background: #007bff;
    border: none;
    border-radius: 0;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem
}

#navRight .nav-item.nav-link.active {
    cursor: default;
    background: #5f90bf;
}

#navRight .nav-item.nav-link:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin-left: 44px;
}

#navRight .nav-item.nav-link:last-child {
    border-top-left-radius: 0;
    border-right: 0;
}

#navRight.show {
    width: 300px;
    height: 100%;
    transition: width 0.5s ease;
    border-left: 1px solid #03a9f4
}

#navRight.hide {
    width: 0;
    height: 0;
    margin-top: 0px;
    transition: width 0.5s ease;
}

#navRight.full-width{
    height: 100%;
    transition: width 0.5s ease;
    border-left: 1px solid #03a9f4
}

#dropdown-route-option, #dropdown-area-option {
    position: absolute;
    right: -5px;
    top: 5px;
    color: #212529;
}

#dropdown-route-option::after, #dropdown-area-option::after {
    display: none;
}

#navRight .tab-content div.listChild {
    height: calc(100% - 46px);
    overflow-y: auto;
    background: #f7f7f7;
}

#navRight .tab-content > div {
    height: 100%;
}

#navRight .tab-content  div.dropdown {
    height: 100%;
}

#navRight .tab-content {
    height: calc(100% - 41px);
}