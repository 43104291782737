.ViewMode {
    position: absolute;
    right: 60px;
    bottom: 25px;
}

.ViewMode .button{
    width: 100px;
    height: 100px;
    display: inline-block;
    border: 1px solid transparent;
    border-radius: 10px;
    margin-left: 10px;
    background: white;
    cursor: pointer;
}

.ViewMode .button svg{
    vertical-align: top;
    position: relative;
    left: 17px;
    top: 10px;
}

.ViewMode .button div.label{
    position: relative;
    margin-left: 23px;
    top: 19px;
}

.ViewMode .dim{
    position: absolute;
    top: -45px;
    right: 0px;
}

.dim-btn {
    display: inline-flex;
    border: 1px solid transparent;
    width: 40px;
    height: 40px;
    margin-right: 5px;
    margin-left: 4px;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 10px;
    cursor: pointer;
}

.ViewMode .button.disabled, .ViewMode .dim-btn.disabled {
    background: #aeaeae;
}

.ViewMode .button.currentSelected, .ViewMode .dim-btn.currentSelected {
    background: #03a9f4;
}

.ViewMode .hide {
    display: none;
}