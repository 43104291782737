@media all and (min-width: 480px) {
    .edit-terminal-multi table,
    th,
    td {
        border: 1px solid black;
        text-align: center;
    }
    .edit-terminal-multi td {
        border: 1px solid black;
        font-size: 13px;
        padding: 2px 15px;
        text-align: center;
        vertical-align: middle
    }
    .col5 {
        text-align: center;
        width: 120px;
        padding-right: 15px;
    }
    .detail-input {
        width: 225px;
        font-size: 13px;
        height: 28px;
    }
}

@media (min-width: 1025px) {
    .edit-terminal-multi td:first-child {
        width: 100px;
    }
}

@media (max-width: 1024px) {
    .edit-terminal-multi td:first-child {
        width: 100px;
    }
}

.contain-right label {
    cursor: pointer;
    margin-left: 5px;
    vertical-align: middle;
    margin-bottom: 0;
    padding: 5px;
}

.icon-select-container {
    border: 1px solid gray;
    padding: 5px;
    display: flex;
    margin: 30px 0;
}

.contain-left {
    width: 100px;
}

.contain-left .label {
    margin-left: 10px;
    margin-top: 5px;
}

.contain-left .icon-preview {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.contain-left .icon-preview img {
    width: 64px;
    margin-top: 10px;
    max-height: 64px;
}

.contain-left .icon-preview svg {
    margin-top: 10px;
    max-height: 64px;
}

.contain-right {
    flex-grow: 1;
    max-height: 130px;
    overflow: auto;
}

.contain-right>div {
    border: 1px solid gray;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.contain-right>div:not(:first-child) {
    border-top: none;
}

.contain-right input[type='radio'] {
    box-sizing: border-box;
    appearance: none;
    background: white;
    outline: 1px solid #333;
    border: 1px solid white;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.contain-right input[type='radio']:checked {
    background: #333;
}