.btn-white {
    background: #ffffff !important;
    color: #1861ac !important;
}

/* .device-display table{
    border: 1px solid black;
    padding-top: 15px;
}

.device-display th, td {
    border: 0px solid black;
    text-align: start;
}

.device-display td {
    font-size: 13px;
} */

.device-display input[type=checkbox] {width: 16px; }

/* .device-display .btn-white:hover{
    background: #ffffff;
    border-color: #1861ac;
} */

.btn-move {
    flex-direction: column;
    margin-left: 15px;
    align-items: center;
    justify-content: center;
}

.btn-move .btn:hover{
    border-color: #1861ac;
}

.btn-move .btn:disabled{
    border-color: #6f7175;
}

.btn-move .btn{
    margin: 5px;
    padding: 5px 10px 5px 10px;
}

.item-selected {
    background-color: #1861ac;
}

.item-selected td{
    color: #ffffff;
}