.operation-history-container {
    background: white;
    position: relative;
    padding: 10px;
}

.operation-history-container .form-container .form-row.row{
    padding-top: 5px;
    padding-bottom: 10px;
}

    .operation-history-container td, .operation-history-container th, .acceleration-history-container td, .acceleration-history-container th {
        text-align: center;
        height: auto;
        padding-top: 0.4rem
    }

.operation-history-container .selectedRow {
    background: #03a9f4;
    color: white;
}

.operation-history-container tr {
    cursor: pointer;
}
.OperationHistoryList, .AccelerationHistoryList {
    height:170px;
    overflow-y:scroll;
}
table.table-fixed thead tr:nth-child(1) th {
    background: #ededed;
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 1px 0 0 1px #adb5bd;
}

.v-middle th {
    vertical-align: middle !important;
}

.up-arrow,
.down-arrow {
    padding-right: 20px !important;
}

.down-arrow::after {
    content: '';
    position: absolute;
    right: 6px;
    top: calc(50% - 4px);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid #000;
    clear: both;
}

.up-arrow::after {
    content: '';
    position: absolute;
    right: 6px;
    top: calc(50% - 4px);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 7px solid #000;
    clear: both;
}