#topTab.show {
    max-height:500px;
    width: 100%;
    transition: height 0.5s ease;
    border-bottom: 1px solid #03a9f4
}

#topTab.hide {
    width: 0;
    height: 0;
    transition: height 0.5s ease;
    overflow: hidden;
}

#topTab {
    background: #f7f7f7;
    overflow: auto;
}

#topTab .bgYellow {
    background: #f3e739;
}

#topTab .bgRed {
    background: #f36d6d;
}

#topTab .bgWhite {
    background: #ffffff;
}
#topTab .toptab-title {
    padding: 0.7rem 1rem;
    text-align: left;
    font-size: 16px;
    position: relative;
    background: #03a9f4;
    color: #fff;
}
#topTab table th, #topTab table td{
    height: 37px
}
    #topTab table th:last-child, #topTab table td:last-child{
        
    }
    #topTab table{
        
    }