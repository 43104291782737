
@media all and (min-width: 480px) {
   
    .terminal-info input[type=number]::-webkit-inner-spin-button {
       opacity: 1;
       width: 225px;
   }

   .terminal-info table, th, td{
       border: 0px solid black;
   }

   .terminal-info td {
       border: 0px solid black;
       font-size: 13px;
       height: 30px;
   }

   .col5 {
       text-align: center;
       width: 120px;
       padding-right: 15px;
   }

 
   .terminal-info .detail-input {
       width: 225px;
       font-size: 13px;
       height: 28px;
   }


}