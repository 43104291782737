header {
    border-bottom: 1px solid #5f90bf
}
a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html { font-size: 14px; }

@media (min-width: 768px) {
    html { font-size: 16px; }
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }


button#dropdown-menu:after {
    display: none;
}


#dropdown-setting, #dropdown-help {
    width: 100%;
    text-align: left;
    color: inherit;
    text-decoration: none;
}

#dropdown-setting:after, #dropdown-help:after {
    position: absolute;
    top: 13px;
    right: 10px;
}

#navtop {
    padding-top: 0;
    padding-bottom: 0;
}

#dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
}

.red {
    color: red;
}

.gray {
    color: gray;
}

.green {
    color: green;
}

.white {
    color: white;
}