@media all and (min-width: 480px) {
   /* #emergency-message {
        /* border: 1px solid black; 
        border-radius: 3px;
        font-size: 19px;
        position: relative;
        padding: 10px 15px;
        top: 20%;
        left: 25px;
        min-width: 400px;
        width: 400px;
        height: 150px;
        background-color: white;
        text-align: center; 
   } */
   #emergency-message button {
        background-color: #00bfe0;
        color: rgb(7, 7, 7);
        text-align: center;
        text-decoration: none;
        font-size: 15px;
        margin-top: 25px;
        margin-bottom: 15px;
        padding: 2px 10px;
        border: 1px solid black;
        border-radius: 5px;
        width: 150px;
        height: 30px;
        
    }


}