.logo {
    text-align: center;
    margin: 20px 0 20px 0;
    position: relative;
}
.logo img{
    width: 200px;
}
.form-login h1{
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 3rem;
    position: relative;
}
.form-login h1:before{
    content: "";
    position: absolute;
    z-index: 1;
    bottom: -10px;
    margin-left: 25px;
    height: 2px;
    background: #0f6674;
    width: 50px;
}
.form-login{
    background: #ffffff;
    padding: 2rem;
    margin: 0 auto;
    max-width: 520px;
    border-radius: 3px;
}
.form-login .btn{
    width: 50%;
    margin: 0 auto;
    background: #38a4dd;
}
.Login {
    overflow: scroll;
    height: 100vh;
}
.Login .errClass {
    color: red;
}
.form-meinte {
    background: #ffffff;
    padding: 2rem;
    margin: 0 auto;
    max-width: 820px;
    border-radius: 3px;
}
.form-meinte h1 {
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 2rem;
    position: relative;
}

.form-meinte h2 {
    text-align: center;
    font-size: 1.0rem;
    position: relative;
}
