@media all and (min-width: 480px) {
    .box {
        padding: 0;
        position: relative;
    }

    .user-details {
        margin-left: -1rem;
        margin-right: -1rem;
        margin-top: -1rem;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
        background: #efefef
    }

    .user-details .user-image {
        position: absolute;
        top: 12px;
        z-index: 1;
    }

    .user-details .user-image .image {
        width: 150px;
        height: 150px;
        position: relative;
    }

    .user-details .user-image .image .bg-transfer {
        border-radius: 100%;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .user-details .description {
        /* padding: 40px 0 20px 170px; */
        padding: 20px 0 0 20px;
    }

    .user-details .description h3 {
        opacity: 0.4;
        color: inherit;
        font-size: 14px;
        margin: 0;
    }

    .user-details .description h2 {
        font-size: 16px;
        margin: 3px 5px 15px 5px;
        font-weight: normal;
        font-size: 1.3rem
    }

    .user-details .description hr {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    fieldset {
        border: 1px solid #cccccc;
        padding: 1rem;
        padding-bottom: 0;
    }

    legend {
        display: inline-block;
        width: auto;
        font-size: 1rem;
        font-weight: bold;
        padding: 0 3px;
        margin: 0;
    }

    .text-error {
        color: red;
    }

    .error > input.form-control {
        border: 1px solid red;
    }
    .accoutInfo-button .btn {
        min-width: 130px
    }
}
