#div-terminal-list button {
    right: 20px;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    
}
#div-terminal-list button:hover {
    background-color: rgba(0, 0, 0, 0);
    border: none;
}

#div-terminal-list button:focus {
    background-color: rgba(0, 0, 0, 0);
    border: none;
}

#div-terminal-list button:focus-visible {
    background-color: rgba(0, 0, 0, 0);
    border: none;
}

#div-terminal-list td {
    vertical-align: middle;
    text-align: center;
    
}

#div-terminal-list th {
    vertical-align: middle;
    text-align: center;
}

#dropdown-terminal-option {
    position: absolute;
    right: -5px;
    top: -5px;
    color: #212529;
    background-color: #00000000;
    border: 0px;
}

#dropdown-terminal-option::after {
    display: none;
}

.blink-bg{
   
    animation: blinkingBackground 5s infinite;
}
@keyframes blinkingBackground{
    0%		{ background-color: #ffffff;}
    50%	    { background-color: #f55c66;}
    100%	{ background-color: #ef0a1a;}
}

#navLeft.show {
    width: 300px;
    transition: width 0.5s ease;
    border-right: 1px solid #03a9f4
}

#navLeft.full-width {
    width: inherit !important;
}

#navLeft.hide {
    width: 0;
    height: 0;
    transition: width 0.5s ease;
}

#accordion {
    height: calc(100% - 44px);
}

#div-terminal-list .table-responsive {
    height: 100%;
    overflow-y: auto;
}

#navLeft {
    overflow: hidden;
}

.bgOverSpeed {
    background-color: red;
}

.bgWrongRoute {
    background-color: yellow;
}

@keyframes blinkingBackgroundName{
    0%		{ background-color: #f55c66;}
    50%	    { background-color: #cf2c37;}
    100%	{ background-color: #ef0a1a;}
}
.bgEmergency {
    animation: blinkingBackgroundName 5s infinite;
}

.bgTerminalNormal {
    background-color: #00000000;
}

.clickable {
    cursor: pointer;
}
